<template>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  metaInfo: {
    meta: [
      { name: 'robots', content: 'noindex, nofollow' },
    ],
  },
})
export default class EmailVerification extends Vue {
  async created() {
    const user_id = this.$route.query.user_id;
    const timestamp = this.$route.query.timestamp;
    const signature = this.$route.query.signature;

    const status = await fetch(`${process.env.VUE_APP_API_LOCATION}/api/v1/verify-registration/`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id,
        timestamp,
        signature,
      }),
    }).then((response) => response.status);
    this.$router.push({ path: '/login', query: { status, origin: 'email-verification' } });
  }
}

</script>

<style scoped>
</style>
